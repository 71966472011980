import axios from 'axios';

axios.defaults.timeout = 180000;
// api
export const ALBUMS_GOG_HTML_URL = 'https://albums.wix.com/gog.html';

export const getAllGogs = async (instanceId: string, instance: string) => {
  const result = await axios.get(
    `https://www.wix.com/_api/albums-webapp/v1/gogs/${instanceId}?instance=${instance}`,
  );
  return result.data;
};

export const getGogAlbums = async (
  instanceId: string,
  instance: string,
  gogId: string,
) => {
  const result = await axios.get(
    `https://www.wix.com/_api/albums-node-server/getGogData?instanceId=${instanceId}&instance=${instance}&gogId=${gogId}`,
  );
  return result.data;
};

export const addAlbumToGog = async (
  instance: string,
  instanceId: string,
  gogId: string,
  albumId: string,
  orderIndex: number,
) => {
  const result = await axios.post(
    `https://www.wix.com/_api/albums-webapp/v1/gogs/${instanceId}/${gogId}/albums/?instance=${instance}`,
    { albumId, orderIndex },
  );
  return result.data;
};

export const removeAlbumFromGog = async (
  instance: string,
  instanceId: string,
  gogId: string,
  albumId: string,
) => {
  const result = await axios.delete(
    `https://www.wix.com/_api/albums-webapp/v1/gogs/${instanceId}/${gogId}/albums/${albumId}?instance=${instance}`,
  );
  return result.data;
};

export const getGogFromHtml = async (instance: string) => {
  const result = await axios.get(
    `https://www.wix.com/_api/albums-node-server/mobile/albums`,
    {
      headers: {
        authorization: instance,
      },
    },
  );
  return result.data;
};

export const getMasterGogData = async () => {
  const result = await axios.get(
    `https://www.wix.com/_api/albums-node-server/getMasterGogData`,
  );
  return result.data;
};

export const checkIfHasBusinessPackage = async (
  metasiteId: string,
  locale: string,
  instance: string,
) => {
  try {
    const result = await axios.get(
      `https://www.wix.com/_api/albums-node-server/hasBusinessPackage?metasiteId=${metasiteId}&locale=${locale}`,
      {
        headers: {
          Authorization: instance,
        },
      },
    );
    return result.data;
  } catch (e) {
    console.log({ e });
  }
};

export const getPublishedAlbums = async (
  instance: string,
  instanceId: string,
) => {
  try {
    const result = await axios.get(
      `https://www.wix.com/_api/albums-node-server/getAlbums?instanceId=${instanceId}&instance=${instance}&filter=published`,
      {
        headers: {
          Authorization: instance,
        },
      },
    );
    return result.data;
  } catch (e) {
    console.log({ e });
  }
};

export const updateAlbum = async (
  instanceId: string,
  gogId: string,
  instance: string,
  albumId: string,
  orderIndex: number,
) => {
  try {
    const result = await axios.put(
      `https://www.wix.com/_api/albums-webapp/v1/gogs/${instanceId}/${gogId}/albums/${albumId}/order?instance=${instance}`,
      { orderIndex },
    );

    return result.data;
  } catch (error) {
    console.log({ error });
  }
};

export const initGogOrderIndex = async (
  instanceId: string,
  gogId: string,
  instance: string,
) => {
  try {
    const result = await axios.get(
      `https://www.wix.com/_api/albums-node-server/initGogOrderIndex?instanceId=${instanceId}&instance=${instance}&gogId=${gogId}`,
    );
    return result.data;
  } catch (error) {
    console.log({ error });
  }
};
